import React, { useEffect } from 'react'

// Nextjs
import Head from 'next/head'
import Router from 'next/router'

// nProgress
import nProgress from 'nprogress'

// AOS
import Aos from 'aos'
import 'aos/dist/aos.css'

// @emotion
import { CacheProvider } from '@emotion/react'

// MUI
import { CssBaseline, ThemeProvider } from '@mui/material'

// Uitls
import { createEmotionCache } from '../utils/create-emotion-cache'

// Layout
import Layout from '../layout/layout'

// Error Boundary - Client Side Error Handling
import ErrorBoundary from '../components/Error-Boundary'

const clientSideEmotionCache = createEmotionCache()

// next Router + nProgress
Router.events.on('routeChangeStart', nProgress.start)
Router.events.on('routeChangeError', nProgress.done)
Router.events.on('routeChangeComplete', nProgress.done)

const MyApp = props => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  useEffect(() => {
    Aos.init({
      duration: 1000,
    })
  }, [])

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Pappad - The Best Food Delivery Service</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5, shrink-to-fit=no"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PHJF2X4');
              `,
          }}
        />
      </Head>
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PHJF2X4" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
      <ErrorBoundary>
        <CssBaseline />

        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ErrorBoundary>
    </CacheProvider>
  )
}

export default MyApp
