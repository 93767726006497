import React from 'react'

const Layout = props => {
  const { children } = props

  return (
    <>
      {children}
    </>
  )
}

export default Layout
