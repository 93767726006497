import React, { Component } from 'react'


class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    console.log('error boundary => ', error)
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log('error-boundary 22')
    console.log({ error, errorInfo })
  }

  render() {
    // Check if the error is thrown
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h2>Oops, there is an error!</h2>
        </div>
      )
    }

    // Return children components in case of no error

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children
  }
}

export default ErrorBoundary
